import { lazy } from 'react';

export default [
  {
    path: '/book',
    element: lazy(() => import('../../components/Booking/BookVenue')),
    auth: true,
  },
  {
    path: '/games-list',
    element: lazy(() => import('../../components/Booking/BookingList')),
    auth: true,
  },
  {
    path: '/game-details',
    element: lazy(() => import('../../components/Booking/BookingDetails')),
    auth: true,
  },
  {
    path: '/booking-type',
    element: lazy(() => import('../../components/Booking/BookingType')),
    auth: true,
  },
  {
    path: '/split-payment',
    element: lazy(() => import('../../components/Booking/SplitPayment')),
    auth: true,
  },
  {
    path: '/confirm-booking',
    element: lazy(() => import('../../components/Booking/ConfirmBooking')),
    auth: true,
  },
  {
    path: '/confirm-booking/card-payment',
    element: lazy(() => import('../../components/Booking/ConfirmBooking')),
    auth: true,
  },
  {
    path: '/confirm-booking/payment-status',
    element: lazy(() => import('../../components/Booking/ConfirmBooking')),
    auth: true,
  },
  {
    path: '/book/timeslot',
    element: lazy(() => import('../../components/Booking/Timeslot')),
    auth: true,
  },
  {
    path: '/book/footage',
    element: lazy(() => import('../../components/Booking/Footage')),
    auth: true,
  },
  {
    path: '/book/recurring-timeslot',
    element: lazy(() => import('../../components/Booking/RecurringTimeslot')),
    auth: true,
  },
  {
    path: '/book/block-booking',
    element: lazy(() => import('../../components/Booking/block/index')),
    auth: true,
  },
  {
    path: '/book/choose-pitch',
    element: lazy(() => import('../../components/Booking/block/ChoosePitch')),
    auth: true,
  },
  {
    path: '/book/choose-days',
    element: lazy(() => import('../../components/Booking/block/ChooseDays')),
    auth: true,
  },
  {
    path: '/book/choose-duration',
    element: lazy(() =>
      import('../../components/Booking/block/ChooseDuration')
    ),
    auth: true,
  },
  {
    path: '/book/choose-timeslot',
    element: lazy(() => import('../../components/Booking/block/Timeslot')),
    auth: true,
  },
  {
    path: '/book/choose-date',
    element: lazy(() => import('../../components/Booking/block/StartDate')),
    auth: true,
  },
  {
    path: '/book/payment-frequency',
    element: lazy(() =>
      import('../../components/Booking/block/PaymentFrequency')
    ),
    auth: true,
  },
  {
    path: '/reschedule/choose-days',
    element: lazy(() =>
      import('../../components/Booking/reschedule/ChooseDays')
    ),
    auth: true,
  },
  {
    path: '/reschedule/choose-timeslot',
    element: lazy(() => import('../../components/Booking/reschedule/Timeslot')),
    auth: true,
  },
  {
    path: '/reschedule/choose-date',
    element: lazy(() =>
      import('../../components/Booking/reschedule/StartDate')
    ),
    auth: true,
  },
];
