import axios from 'axios';
import { filter, find, uniqWith } from 'lodash';
import Visa from '../assets/icons/visa-card.svg';
import Master from '../assets/icons/master-card.svg';
import Maestro from '../assets/icons/maestro-card.svg';
import Amex from '../assets/icons/american-express.svg';
import Discover from '../assets/icons/discover.svg';
import Diners from '../assets/icons/diners-club.svg';
import Jcb from '../assets/icons/jcb.svg';
import Unknown from '../assets/icons/credit-card-icon.svg';

export const isUserLoggedIn = () => false;

export const getHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  };
};

export const getFormHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: `Token ${token}`,
  };
};

export const scorePassword = (password) => {
  let score = 20;
  if (!password) return score;
  if (password.length < 5) {
    score += 5;
  }
  // -- 5 to 7 characters
  else if (password.length > 4 && password.length < 8) {
    score += 10;
  } else if (password.length >= 8) {
    score += 20;
  } // bonus points for mixing it up
  let variations = {
    digits: /\d/.test(password),
    lower: /[a-z]/.test(password),
    upper: /[A-Z]/.test(password),
    nonWords: /\W/.test(password),
  };
  let variationCount = 0;
  for (let check in variations) {
    variationCount += variations[check] == true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;
  return parseInt(score);
};

export const http = async (
  path = '/',
  {
    method = 'get',
    body,
    withoutAuth = true,
    serializeBody = false,
    getRawData = false,
  } = {}
) => {
  try {
    if (!path) {
      throw new Error('API path not defined!');
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (!withoutAuth) {
      const token = localStorage.getItem('token');
      headers.Authorization = `Token ${token}`;
    }

    let url =
      process.env.REACT_APP_API_ENDPOINT +
      (path[0] !== '/' ? `/${path}` : path);
    let data;

    if (body && (method === 'get' || serializeBody)) {
      url +=
        '?' +
        Object.keys(body)
          .map((key) => key + '=' + body[key])
          .join('&');
    } else {
      data = { ...body };
    }

    const apiResponse = await axios({
      url,
      data,
      method,
      headers,
    });
    if (!(apiResponse.status === 200 || apiResponse.status === 201)) {
      if (`${apiResponse.status}`.slice(0, 2) === '40') {
        alert('Authentication Error!');
      }
      throw new Error();
    }

    if (getRawData) {
      return apiResponse.data;
    }

    const responseData = apiResponse.data;
    if (!(apiResponse.status === 200 || apiResponse.status === 201)) {
      throw new Error(
        responseData.error
          ? JSON.stringify(responseData.error) // FIXME: either error or errors
          : responseData.message
      );
    }
    return responseData;
  } catch (error) {
    throw new Error();
  }
};

export const getAddressGMap = async (postcode) => {
  try {
    const response = await axios({
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${postcode}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    });
    return response.data.results;
  } catch (e) {
    console.log(e);
  }
};

export const getAddressAPI = async (postcode) => {
  try {
    const response = await axios({
      method: 'get',
      url: `https://api.getAddress.io/find/${postcode}/?api-key=${process.env.REACT_APP_ADDRESS_API_KEY}&expand=true`,
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const validateCreditCardNumber = (ccNum) => {
  const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  const amexpRegEx = /^(?:3[47][0-9]{13})$/;
  const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  const meastroRegEx = /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/;

  if (visaRegEx.test(ccNum)) {
    return true;
  } else if (mastercardRegEx.test(ccNum)) {
    return true;
  } else if (amexpRegEx.test(ccNum)) {
    return true;
  } else if (discovRegEx.test(ccNum)) {
    return true;
  } else if (meastroRegEx.test(ccNum)) {
    return true;
  }
  return false;
};

export const loadStripeKey = (ground) => {
  const grounds = [
    {
      name: 'Hamilton',
      key:
        process.env.REACT_APP_STRIPE_TYPE === 'TEST'
          ? process.env.REACT_APP_STRIPE_HAMILTON_TEST_KEY
          : process.env.REACT_APP_STRIPE_HAMILTON_LIVE_KEY,
    },
    {
      name: 'Leeds',
      key:
        process.env.REACT_APP_STRIPE_TYPE === 'TEST'
          ? process.env.REACT_APP_STRIPE_LEEDS_TEST_KEY
          : process.env.REACT_APP_STRIPE_LEEDS_LIVE_KEY,
    },
    {
      name: 'Trafford',
      key:
        process.env.REACT_APP_STRIPE_TYPE === 'TEST'
          ? process.env.REACT_APP_STRIPE_TRAFFORD_TEST_KEY
          : process.env.REACT_APP_STRIPE_TRAFFORD_LIVE_KEY,
    },
    {
      name: 'Wigan',
      key:
        process.env.REACT_APP_STRIPE_TYPE === 'TEST'
          ? process.env.REACT_APP_STRIPE_WIGAN_TEST_KEY
          : process.env.REACT_APP_STRIPE_WIGAN_LIVE_KEY,
    },
    {
      name: 'Yardley',
      key:
        process.env.REACT_APP_STRIPE_TYPE === 'TEST'
          ? process.env.REACT_APP_STRIPE_YARDLEY_TEST_KEY
          : process.env.REACT_APP_STRIPE_YARDLEY_LIVE_KEY,
    },
  ];

  const keys = find(grounds, { name: ground?.ground_name || '' });

  return keys
    ? keys.key
      ? keys.key
      : process.env.REACT_APP_STRIPE_TEST_KEY
    : process.env.REACT_APP_STRIPE_TEST_KEY;
};

export const loadCardIcon = (cardType) => {
  let icon = '';

  switch (cardType) {
    case 'visa':
      icon = Visa;
      break;
    case 'mastercard':
      icon = Master;
      break;
    case 'maestro':
      icon = Maestro;
      break;
    case 'amex':
      icon = Amex;
      break;
    case 'discover':
      icon = Discover;
      break;
    case 'diners':
      icon = Diners;
      break;
    case 'jcb':
      icon = Jcb;
      break;
    default:
      icon = Unknown;
      break;
  }

  return icon;
};

export const getMonth = (month) => {
  if (month) {
    switch (month) {
      case '01':
        return 'JAN';
      case '02':
        return 'FEB';
      case '03':
        return 'MAR';
      case '04':
        return 'APR';
      case '05':
        return 'MAY';
      case '06':
        return 'JUN';
      case '07':
        return 'JUL';
      case '08':
        return 'AUG';
      case '09':
        return 'SEP';
      case '10':
        return 'OCT';
      case '11':
        return 'NOV';
      case '12':
        return 'DEC';
      default:
        return '';
    }
  }
  return '';
};

export const getFirstAndLastDay = () => {
  let date = new Date();
  let today = formatDate(
    new Date(date.getFullYear(), date.getMonth(), date.getDate())
  );
  let firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
  let lastDay = formatDate(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  return { today, firstDay, lastDay };
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export const check = (array, key, value) => {
  return array.some((object) => object[key] === value);
};

export const checkValue = (array, value) => {
  return array?.some((object) => object === value);
};

export const horizontalScroll = (e) => {
  const container = e.currentTarget;
  const scrollWidth = container.scrollWidth;
  const clonesWidth = getClonesWidth();
  let scrollPos = container.scrollLeft;
  let scrollPosAdd;
  container.clientWidth > clonesWidth
    ? (scrollPosAdd = container.clientWidth)
    : (scrollPosAdd = clonesWidth);

  return scrollPos + scrollPosAdd >= scrollWidth ? true : false;
};

const getClonesWidth = () => {
  const clones = document.getElementsByClassName('is-clone');
  let clonesWidth = 0;
  for (let i = 0; i < clones.length; i++) {
    clonesWidth = clonesWidth + clones[i].clientWidth;
  }
  return clonesWidth;
};

export const loadCards = (savedCard) => {
  const cards = filter(
    savedCard.data,
    (obj) => obj.id.indexOf('card_') !== -1 || obj.id.indexOf('src_') !== -1
  );
  const unique = uniqWith(
    cards,
    (a, b) =>
      a.card.exp_month === b.card.exp_month &&
      a.card.exp_year === b.card.exp_year &&
      a.card.last4 === b.card.last4
  );

  return unique;
};

export const getNextGameDate = (bookingDates) => {
  const dates = filter(bookingDates, (dates) => {
    return (
      dates.game_status === 'Today matches' ||
      dates.game_status === 'Upcoming matches'
    );
  });

  return dates.length > 0 ? dates[0]?.start_date : '';
};

export const colourNameToHex = (colour) => {
  const colours = {
    '#000000': 'Black',
    '#ffffff': 'White',
    '#f00000': 'Red',
    '#0000FF': 'Blue',
    '#FFFF00': 'Yellow',
    '#00FF00': 'Green',
    '#A020F0': 'Purple',
    '#FFA500': 'Orange',
  };

  if (typeof colours[colour] != 'undefined') return colours[colour];

  return false;
};
