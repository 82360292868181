import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const NotificationModal = ({ modal, setModal, bookingId, onClickClose }) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={modal}
      animation={true}
      centered
      className='popup-more'
      onHide={() => setModal(false)}
    >
      <Modal.Header>
        <Modal.Title>
          <h3 className='mb-0 fw-bold'>Your game is 72 hours away</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='fs-15'>
          As the lead booker, you are responsible for the full cost of the
          booking. If you have invited other players to share the cost of the
          booking, this will be deducted from the total you pay.
        </p>
        <p className='fs-15 mb-0'>
          As a reminder, payment is taken from all paying players 30 minutes
          after your scheduled booking time.
        </p>
        <Button
          className='action-btn-sm  bg-brand-accent w-100 mt-4'
          onClick={() => {
            setModal(false);
            onClickClose();
          }}
        >
          Done
        </Button>
        <p
          className='text-center mt-3 text-grey underline'
          onClick={() => {
            onClickClose();
            navigate('/game-details', {
              state: {
                booking_id: bookingId,
                is_lead_booker: true,
              },
            });
          }}
        >
          Manage this booking
        </p>
      </Modal.Body>
    </Modal>
  );
};

NotificationModal.propTypes = {
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  bookingId: PropTypes.number,
  onClickClose: PropTypes.func,
};

export default NotificationModal;
