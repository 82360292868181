import axios from 'axios';
import { getHeaders } from '../commonUtils';

export const axiosPATCH = (url, payload) => {
  return axios
    .patch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const axiosPUT = (url, payload) => {
  return axios
    .put(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const axiosGET = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const axiosPOST = (url, payload) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const axiosDELETE = (url) => {
  return axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
